<template>
  <page>
    <empty description='敬请期待'>
      <button @click="$router.back()" class="btn btn_radius theme_bg margin_auto" style="width: 200px">
        返回
      </button>
    </empty>
  </page>
</template>

<script>
import {Empty} from 'vant';

export default {
  components: {
    Empty
  }
}
</script>